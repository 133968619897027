.portfolio_content{
    width: 100%;
    padding-top: 15px;
    padding-left: 12px;
    padding-right: 12px;
}
.icon_tec {
    border: 1px solid rgba(92, 91, 91, .3);
    border-radius: 50%;
    height: 40px;
    width: 40px;
    margin-top: 15px;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.icon_tec_img {
    height: 30px;
    width: 30px;
}
.portfolio_img {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    position: relative;
}
.portfolio_content_p {
    text-align: justify;
    margin-bottom: 15px;
    font-size: 15px;  
}

@media (min-width: 1024px) {
    .portfolio_content {
        width: 40%;
        padding-right: 30px;
    }  
    .portfolio_img {
        width: 60%;
        padding-left: 0px;
        padding-right: 0px;          
    }  
}
@media (min-width: 1280px) {
    .portfolio_content {
        padding-left: 60px;
        padding-right: 60px;
    } 
}
@media (min-width: 1700px) {
    .portfolio_content {
        padding-left: 0;
    } 
}





