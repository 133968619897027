
.single-testimonial {
    text-align: center;
    position: relative;
    z-index: 2;
    max-width: 1050px;
    width: 100%;
    margin: 0 auto;
}
.testimonial-section .author-img-wrapper:before {
    content: url("../assets/images/curve.webp");
    position: absolute;
    bottom: -30px;
    left: 115px;
    overflow: hidden;
}
.testimonial-section .author-img-wrapper.animated:before {
    width: 1100px;
}
.quote {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 0;
    z-index: -1;
}
.content {
    text-align: center;
    position: relative;
    padding-top: 140px;
}
.content h2 {
    font-style: italic;
    font-weight: 500;
    font-size: 30px;
}
.author-img {
    height: 150px;
    width: 150px;
    padding: 5px;
    border-radius: 100%;
}
.author-img-wrapper {
    margin: 20px 0 0 300px;
    position: relative;
    background: transparent;
    padding-left: 0px;
    padding-right: 0px;
    width: 160px;
    height: 160px;
    border-radius: 100%;
    border: 2px dashed #696a69;
    align-items: center;
    display: flex;
    justify-content: center;
}
.author a {
    font-size: 22px;
    font-weight: 700;
    color: #f43534;
    text-transform: capitalize;
    font-family: 'Roboto', sans-serif;
    display: block;
    margin-top: 26px;
    text-decoration: none;
}

.author span {
    display: block;
    font-size: 14px;
    text-transform: none;
    color: #f43534;
}

@media (max-width: 575px) {
    .author-img-wrapper {
        margin: 80px 0 0 80px;
        width: 140px;
        height: 140px;
    }

    .author-img {
        height: 130px;
        width: 130px;
    }

    .quote {
        max-width: 150px;
    }

    .content {
        padding-top: 55px;
    }

    .content h2 {
        font-size: 18px;
    }
}