.badge {
    letter-spacing: 1px;
    color: #c78758;
    margin-bottom: 1em;
    align-items: center;
    display: flex;
    gap: 5px;
    text-transform: uppercase;
}

.badge span {
    border: 1px solid rgba(92, 91, 91, .3);
    padding: 5px 7px;
    border-radius: 999px;
}