
    /*  */


    .blog-section .ellipse {
        position: absolute;
        top: -150px;
        left: 200px;
        max-width: 590px;
        z-index: 1;
        opacity: 0.5;
        animation: ellipse-animate 2s linear infinite;
    }

    @-webkit-keyframes ellipse-animate {
        0% {
            transform: rotate(40deg)
        }

        100% {
            transform: rotate(350deg)
        }
    }

    @keyframes ellipse-animate {
        0% {
            transform: rotate(40deg)
        }

        100% {
            transform: rotate(350deg)
        }
    }

    .blog-section .section-title {
        padding-bottom: 35px;
    }

 
    .blog-section .single-blog {
        background-color: rgba(128, 128, 128, .1);
        display: flex;
        flex-flow: column;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 20px;
        margin-left: 0;
    }

    .blog-section .single-blog .featured-img img {
        width: 100%;
        -webkit-filter: grayscale(100%);
        filter: grayscale(100%);
    }

    .blog-section .single-blog:hover .featured-img img {
        opacity: 1;
        -webkit-filter: grayscale(0);
        filter: grayscale(0);
    }

    .blog-section .single-blog.primary {
        background: transparent;
        display: block;
    }

    .blog-section .single-blog .post-title {
        padding: 24px 30px 30px;
        text-align: center;
    }

    .blog-section .single-blog .post-title h3 {
        font-size: 16px;
        line-height: 1.3;
    }

    .blog-section .single-blog .post-title h3 a {
        color: #fff;
    }

    .blog-section .single-blog .post-title h3 a:hover {
        color: #f43534;
    }

    .blog-section .single-blog .like-comment {
        display: flex;
        flex-flow: row;
        align-items: center;
        justify-content: space-between;
        padding: 20px 30px;
        border-top: 1px solid rgba(92, 91, 91, var(--tw-border-opacity));
        --tw-border-opacity: .2;
        position: relative;
    }

    .blog-section .single-blog .like-comment .read-more {
        width: 45px;
        height: 45px;
        border: 1px solid rgba(92, 91, 91, var(--tw-border-opacity));
        --tw-border-opacity: .2;
        display: flex;
        border-radius: 100%;
        flex-flow: column;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: -24px;
        left: 50%;
        transform: translateX(-50%);
        background: #1f2022;
    }

    .blog-section .single-blog .like-comment span {
        color: #8b8a8a;
        font-size: 16px;
        font-weight: 700;
        display: flex;
        align-items: center;
    }
