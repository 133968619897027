.container-resources {
    position: relative;
}

.card-resource-primary {
    border-radius: 20px;
    margin-bottom: 15px;
    position: relative;
    background: linear-gradient(to left, rgba(0, 0, 0, 0) 1%, rgba(78, 78, 78, 0.50) 100%, rgba(78, 78, 78, 0.1) 10%);
}

.hero-content {
    padding: 1.5rem;
}

.card-resource-primary::after {
    content: '';
    border-radius: 20px;
    top: -4px;
    left: -4px;
    right: -4px;
    bottom: -4px;
    padding: 2px;
    color: #f9f9f9;
    display: block;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    transition: opacity .1s ease-out;
    background: linear-gradient(to bottom, rgba(255, 255, 255, .2), rgba(255, 255, 255, .4));
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
}

.card-resource-primary:hover::after {
    opacity: 1;
}


@media (min-width: 1024px) {
    .container-resources {
        display: flex;
    }

    .card-resource-primary {
        flex: 1 1 60%;
        margin-bottom: 0;
    }

    .container-resources-articles {
        flex: 1 1 40%;
        height: 91px;
        padding-left: 40px;
    }

}