:root {
    --background-1: radial-gradient(farthest-side at top left, rgba(0, 194, 255, 0.3), transparent 30%), radial-gradient(farthest-corner at bottom right, rgba(255, 50, 50, 0.3), transparent 30%);
    --background-4: radial-gradient(farthest-corner at top right, rgba(255, 50, 50, 0.3), transparent 30%), radial-gradient(farthest-corner at bottom left, rgba(0, 194, 255, 0.3), transparent 30%);
}

.header-section {
    background: radial-gradient(farthest-side at top left, rgba(0, 194, 255, 0.3), transparent 30%), radial-gradient(farthest-corner at bottom right, rgba(255, 50, 50, 0.3), transparent 30%);
}

.portfolio-section {
    background: var(--background-4);
    padding-top: 25px;
}

.portfolio-information-section {
    padding-top: 120px;
    background: var(--background-1);
}

.portfolio-section-right {
    background: var(--background-2);
}

.testimonial-section {
    overflow: hidden;
    padding-top: 120px;
    padding-bottom: 120px;
    background: var(--background-1);
}

.skill-information-section {
    background: radial-gradient(farthest-corner at bottom left, rgba(0, 194, 255, 0.3), transparent 30%), radial-gradient(farthest-corner at top right, rgba(255, 50, 50, 0.4), transparent 30%);
}

.blog-information-section {
    background: radial-gradient(farthest-corner at bottom left, rgba(0, 194, 255, 0.3), transparent 30%), radial-gradient(farthest-corner at top right, rgba(255, 50, 50, 0.3), transparent 30%);
}

.resources-information-section {
    padding-top: 120px;
    background: radial-gradient(farthest-corner at bottom left, rgba(0, 194, 255, 0.3), transparent 30%), radial-gradient(farthest-corner at top right, rgba(255, 50, 50, 0.3), transparent 30%);
}

.blog-section {
    background: radial-gradient(farthest-corner at top left, rgba(0, 194, 255, 0.3), transparent 30%), radial-gradient(farthest-corner at bottom right, rgba(255, 50, 50, 0.3), transparent 30%);
}

.skill-section {
    padding-bottom: 120px;
    background: radial-gradient(farthest-corner at top left, rgba(0, 194, 255, 0.3), transparent 30%), radial-gradient(farthest-corner at bottom right, rgba(255, 50, 50, 0.3), transparent 30%);
}

.resources-section {
    padding-bottom: 50px;
    background: radial-gradient(farthest-corner at top left, rgba(0, 194, 255, 0.3), transparent 30%), radial-gradient(farthest-corner at bottom right, rgba(255, 50, 50, 0.3), transparent 30%);
}


.section-bottom-shape {
    position: relative;
    left: 0;
    bottom: 0px;
    width: 100%;
    height: 50px;
    display: none;
}

.section-bottom-shape img {
    height: 50px;
    width: 100%;

}

@media (min-width: 1024px) {
    .section-bottom-shape {
        display: block;
        height: 100px;
    }

    .section-bottom-shape img {
        height: 100px;
    }
}

@media (min-width: 1024px) {
    .portfolio-information-section {
        background: radial-gradient(farthest-corner at top left, rgba(0, 194, 255, 0.3), transparent 30%), radial-gradient(farthest-corner at bottom center, rgba(255, 50, 50, 0.3), transparent 40%);
    }

    .skill-information-section {
        background: radial-gradient(farthest-corner at top right, rgba(255, 50, 50, 0.4), transparent 30%), radial-gradient(farthest-corner at bottom center, rgba(255, 50, 50, 0.3), transparent 40%);
    }

    .blog-information-section {
        background: radial-gradient(farthest-corner at bottom center, rgba(255, 50, 50, 0.3), transparent 40%), radial-gradient(farthest-corner at top center, rgba(0, 194, 255, 0.1), transparent 50%);
    }

    .resources-information-section {
        background: radial-gradient(farthest-corner at top left, rgba(0, 194, 255, 0.3), transparent 30%), radial-gradient(farthest-corner at bottom center, rgba(255, 50, 50, 0.3), transparent 40%);
    }

    .blog-section {
        padding-bottom: 100px;
        background: radial-gradient(farthest-corner at top center, rgba(255, 50, 50, 0.3), transparent 40%), radial-gradient(farthest-corner at bottom left, rgba(0, 194, 255, 0.3), transparent 30%);
    }

    .skill-section {
        background: radial-gradient(farthest-corner at top center, rgba(255, 50, 50, 0.3), transparent 40%);
    }

    .portfolio-section-right {
        background: radial-gradient(farthest-corner at top center, rgba(255, 50, 50, 0.3), transparent 40%), radial-gradient(farthest-corner at bottom left, rgba(0, 194, 255, 0.3), transparent 30%);
    }

    .resources-section {
        padding-bottom: 50px;
        background: radial-gradient(farthest-corner at top center, rgba(255, 50, 50, 0.3), transparent 40%), radial-gradient(farthest-corner at bottom left, rgba(0, 194, 255, 0.2), transparent 30%);
    }

    .testimonial-section {
        padding-top: 200px;
        padding-bottom: 200px;
    }

}