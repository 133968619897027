.presentation-section {
    padding-top: 70px;
}

.presentation-walper::before {
    content: "";
    width: 260px;
    height: 260px;
    /* background:linear-gradient(131.79deg, rgba(0, 194, 255, 0.5) 3.6%, rgba(67, 66, 77, 0.1) 48.41%, rgba(200, 0, 0, 0.5) 96.11%); */
    background: rgb(255,255,255,.05) ;
    box-sizing: border-box;
    border-radius: 100%;
    margin-top: 40px;
}

.presentation-img{
    position: absolute;
    filter: drop-shadow(2px 4px 6px black);
    position: absolute;
    left: 0;
    right: 0;
    width: 280px;
}

.presentation-description {
    position: absolute;
    border-radius: 999PX;
    border: 1px solid rgba(92, 91, 91, .3);
    backdrop-filter: none;
    gap: 7px;
    top: 25%;
    background: rgb(21,21,21,.9);
    padding: 7px 7px;
}

.presentation-description-img img{
    width: 38px;
    height: 38px;
}

.presentation-content h2 {
    font-size: 12px;
    font-weight: 500;
    padding-bottom: 4px;
}

.presentation-content img {
    margin-left: -1px;
}

.social-icon {
    position: absolute;
    right: 3px;
    bottom: 0;
    top: 60px;
}

.social-icon li a {
    width: 40px;
    height: 40px;
    color: #fff;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    opacity: .7;
}

@media (min-width: 1024px) {
    .presentation-section {
        padding-top: 100px;
    }

    .presentation-section .section-title {
        bottom: -5px;
        position: absolute;
        left: 10px;
    }

    .presentation-walper::before {
        height: 400px;
        width: 400px;
    }

    .presentation-img{
        bottom: 0;
        width: auto;
    }
    
    .presentation-description {
        padding: 10px 20px;
        background: none;
        backdrop-filter: blur(30px);
        top: 29%;
        gap: 10px;
        left: 18%;
    }

    .presentation-content h2 {
        font-size: 14px;
    }

    .presentation-description-img img{
        width: 41px;
        height: 41px;
    }

    .presentation-img img {
        width: auto;
    }

    .social-icon {
        left: 4px;
        top: 350px;
        width: 40px;
    }


}

@media (min-width: 1280px) {
    .presentation-description {
        left: 23%;
    }

    .presentation-section .section-title {
        left: 53px;
        bottom: -10px;
    }

    .social-icon {
        left: 50px;
        top: 250px;
    }
}

@media (min-width: 1400px) {
    .presentation-description {
        left: 26%;
    }
}