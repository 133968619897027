.ctn-check {
    gap: 10px;
    justify-content: center;
}
.check {
    height: 30px;
    width: 30px;
    border-radius: 100%;
    background-color: rgb(31, 31, 31,.5);
}

