.container-footer {
    border-top: 1px solid;
    --tw-border-opacity: .2;
    border-color: rgba(92, 91, 91, var(--tw-border-opacity));
    padding-top: 30px;
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 30px;
    position: relative;
    padding-left: 12px;
    padding-right: 12px;
}

@media (min-width: 1280px) {
    .container-footer {
        padding-left: 60px;
        padding-right: 60px;
    }
}