.link-btn {
    display: inline-flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 2;
    background: transparent;
    border: 1px solid rgba(92, 91, 91);
    opacity: .5;
    width: 48px;
    height: 48px;
    border-radius: 100%;
    cursor: pointer;
}
.link-btn:hover {
    opacity: 1;
}